<template>
  <div>
    <el-input v-model="orderId" placeholder="请输入订单号" style="width:240px" @keyup.enter.native="search(orderId)"></el-input>
    <el-button type="primary"  @click="search(orderId)">搜索</el-button>
    <el-form label-width="120px">
     <el-form-item label="下载模板">
       <a href="https://linggan-1316937351.cos.ap-shanghai.myqcloud.com/jdorder-template.xlsx">订单批量查询模板.xlsx</a>
       </el-form-item>
      <el-form-item label="选择Excel">
        <el-upload
          ref="dragupload"
          :auto-upload="false"
          :on-success="fileUploadSuccess"
          :on-error="fileUploadError"
          :disabled="importBtnDisabled"
          :limit="1"
          :headers="mytoken"
          :action="'/api/jd/orderId/lg/batchGet'"
          :data="uploadData"
          :on-remove="handleRemove"
          name="file">
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          <el-button
            :loading="loading"
            style="margin-left: 10px;"
            size="small"
            type="success"
            @click="submitUpload">灵感订单批量查询</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      border
      @filter-change="filterChange"
      ref="filterTable"
      style="width: 100%" v-loading="loading" height="80%">
      <template slot="empty">
        <p class="emptyText">{{ tableEmptyText }}</p>
    </template>

       <el-table-column
        label="商品信息" width="180">
         <template slot-scope='scope'>
         <p>{{scope.row.skuName}}</p>
           <p>店铺名称:{{ shouldDisplayShopName(scope.row) }}</p>
         <!--
         <p>图链接:{{scope.row.goodsInfo.imageUrl}}</p>
         
         -->
        </template>
      </el-table-column>

       <el-table-column
        prop="skuId"
        label="skuId">
      </el-table-column>

      <el-table-column
        prop="price"
        label="商品单价">
      </el-table-column>

      <el-table-column
        prop="orderId"
        label="订单号">
      </el-table-column>

      <el-table-column
        prop="parentId"
        label="父单号">
      </el-table-column>

      <el-table-column
        prop="validCodeMsg"
        :formatter="patternformatter"
        label="订单状态">
      </el-table-column>

<el-table-column
        prop="errMsg"
        label="异常信息">
      </el-table-column>

      <el-table-column
        prop="bonusText"
        label="奖励活动状态"
        :filter-multiple="false"
    :filters="filterStatus"
     column-key="activity"
        >
        
      </el-table-column> 
      <el-table-column
        prop="activityName"
        label="奖励活动名称">
      </el-table-column> 
        <el-table-column
        prop="plus"
        :formatter="plusFormat"
        label="是否为plus订单">
      </el-table-column>
       <el-table-column
        label="订单时间"
        >
        <template slot-scope='scope'>
         <p>下单时间:{{scope.row.orderTime}}</p>
         <p>完成时间:{{scope.row.finishTime}}</p>
         <p>预估结算时间:{{scope.row.payMonth}}</p>
        </template>
      </el-table-column>

      <el-table-column
        label="商品数量">
         <template slot-scope='scope'>
         <p>商品数量:{{scope.row.skuNum}}</p>
         <p>退货数量:{{scope.row.skuReturnNum}}</p>
         <p>售后中数量:{{scope.row.skuFrozenNum}}</p>
        </template>
      </el-table-column>

      <el-table-column
        prop="estimateCosPrice"
        label="预估计佣金额">
      </el-table-column>     

      <el-table-column
        prop="estimateFee"
        label="预估佣金">
      </el-table-column>

      <el-table-column
        prop="commissionRate"
        label="佣金比例">
        
      </el-table-column>

      <el-table-column
        prop="finalRate"
        label="分成比例">
      </el-table-column>

      <el-table-column
        prop="actualCosPrice"
        label="实际计佣金额">
      </el-table-column>

       <el-table-column
        prop="actualFee"
        label="实际佣金">
      </el-table-column>

   </el-table>
     <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
 import { getLgJdOrder } from "@/api/jdorder";
  export default {
    data() {
      return {
      orderId: '',
      tableData: [],
      tableData2: [],
      tableEmptyText:'暂无数据',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      importBtnDisabled: false,
      filterStatus: [{text: '在活动中', value: 'true'}, {text: '不在活动中', value: 'false'}],
      };
    },

    computed: {
    mytoken() {
      return {
        "x-auth-token": localStorage.getItem("token"),
      };

    },
    
    },

    methods: {
      filterChange(filter) {
        var inActivity = filter.activity[0]
        if(!inActivity) {
        this.tableData=this.tableData2
        } else if (inActivity == 'true') {
 let newArr = this.tableData2.filter((item) => {
        return item.bonusText != null && item.bonusText.length > 0
      });
      this.tableData = newArr;
        } else {
 let newArr = this.tableData2.filter((item) => {
        return item.bonusText == undefined || item.bonusText == null || item.bonusText == "";
      });
      this.tableData = newArr;
        }
        
    },
      shouldDisplayShopName(row) {
      if (row.goodsInfo && row.goodsInfo.shopName) {
        return row.goodsInfo.shopName;
      } else {
        return "暂无";
      }
    },
       plusFormat(row, column) {
    if (row.plus === 1) {
      return '是'
    } else{
      return '否'
    }
  },
      async search(val){
        this.loading = true;
        await getLgJdOrder({orderId: val})
          .then((res) => {
            this.loading = false;
            if (res.code == 1000) {
      this.tableData = res.data;
      this.tableData2 = res.data;
      this.total = res.data.total;
          } else if (res.code == 10000) {
            this.tableData = [];
            this.tableEmptyText = res.msg
          } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }

      })
    },
    //点击按钮上传文件到接口里面
        submitUpload() {
            this.importBtnDisabled = true
            this.loading = true
            this.$refs.dragupload.submit();
        },
        //上传成功
        fileUploadSuccess(res) {
            //提示信息
            this.loading = false
            this.importBtnDisabled = false
            this.$message({
                type: 'success',
                message: '上传成功'
            })
           if (res.code === 1000) {
            this.tableData = res.data;
             this.tableData2 = res.data;
              this.total = res.data.total;
          } else if (res.code == 10000) {
            this.tableData = [];
            this.tableEmptyText = res.msg
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
        },
        //上传失败
        fileUploadError() {
            this.loading = false
            this.importBtnDisabled = false
            this.$message({
                type: 'error',
                message: '失败'
            })
        },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search(); // 重新搜索以更新分页数据
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search(); // 重新搜索以更新分页数据
    }
    }
  };
</script>

